import "./start.scss";
import "firebase/storage";

import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { Loader } from "../common";
import Logo from "../../images/september_logo.png";
import PlaceHolderImg from "../../images/placeholder.svg";
import Prefetch from "./prefetch";
import axios from "axios";
import config from "../util/config";
import cookie from "react-cookies";
import imageCompression from "browser-image-compression";
import qs from "query-string";
import { storage } from "../util/firebase";
import { useData } from "../util/dataProvider";
import { useHistory } from "react-router-dom";

export default function Start() {
  const [isLoading, setIsLoading] = useState(true);
  const [prefetch, setPrefetch] = useState(false);

  const [options, setOptions] = useState({});
  const history = useHistory();
  const [page, setPage] = useState(1);
  let test = qs.parse(window.location.search).test;
  let testuser_id = qs.parse(window.location.search).m;

  let allow_input = qs.parse(window.location.search).allow_user_input;

  const [initialValues, setInitialValues] = useState({
    test: test ? parseInt(test) : "",
    name: "",
    age: "",
    gender: "",
    slideshow: false,
    debug: false,
    bild: "",
    tags: [],
    testuser_id: testuser_id ? testuser_id : "",
  });

  useEffect(() => {
    let callbackUrl = qs.parse(window.location.search).callback_url;
    if (callbackUrl) {
      let oneDay = new Date(new Date().getTime() + 24 * 3600 * 1000);
      cookie.save("callbackUrl", callbackUrl, {
        path: "/",
        expires: oneDay,
      });
    }
    if (testuser_id) {
      if (Array.isArray(testuser_id)) {
        testuser_id = testuser_id[0];
      }
      let oneDay = new Date(new Date().getTime() + 24 * 3600 * 1000);
      cookie.save("testuser_id", testuser_id, {
        path: "/",
        expires: oneDay,
      });
    }
    const value = {
      age: "",
      bild: "",
      gender: "",
      name: "",
      password: "september#test",
      tags: [],
      test: test,
      testuser_id: testuser_id,
    };
    if (test && testuser_id && (!allow_input || allow_input == "false")) {
      submitForm(value);
    } else {
      const password = localStorage.getItem("september-password");
      setIsLoading(true);
      axios
        .post(`${config.apiUrl}/options`, {
          password: "september#test",
        })
        .then((response) => {
          if (response.data.success === false) {
            localStorage.removeItem("september-password");
            setIsLoading(false);
            alert("Passwort ist nicht richtig");
            window.location.reload();
          } else {
            setOptions(response.data);
            setIsLoading(false);
            setInitialValues((values) => {
              const newValues = values;
              if (!test) {
                newValues.test = response.data.tests[0].id;
              }
              return { ...newValues };
            });
          }
        })
        .catch((error) => {
          localStorage.removeItem("september-password");
          setIsLoading(false);
          alert("Passwort ist nicht richtig");
        });
    }
  }, []);

  const uploadFile = (e, setFieldValue) => {
    if (!e.target.files[0]) return null;
    async function handleImageUpload(event) {
      const imageFile = event.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    }
    var file;
    handleImageUpload(e).then((result) => {
      file = result;
      storage
        .ref("testUsers/image")
        .put(file)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            setFieldValue("bild", url);
          });
        });
    });
  };

  function submitForm(values) {
    const password = localStorage.getItem("september-password");
    if (!values.password) {
      values.password = password;
    }

    axios
      .post(`${config.apiUrl}/create`, values)
      .then((response) => {
        console.log(response);
        if (response.data) {
          history.push(
            `/test/${response.data.post_id}/${
              response.data.slide_show ? "slideshow" : "normal"
            }/${values.debug ? "debug" : "normal"}`
          );
        }
      })
      .catch((error) => {
        alert(error?.response?.message);
      });
  }
  if (isLoading) {
    return <Loader />;
  }

  function validateUsername(value) {
    let error;
    if (!value) {
      error = "Name muss gegeben werden";
    }
    return error;
  }

  function validateTestId(value) {
    let error;
    if (!value) {
      error = "Test Id muss gegeben werden";
    }
    return error;
  }

  function validateOption(value) {
    let error;
    if (!value) {
      error = "Bitte auswählen";
    }
    return error;
  }

  if (test && testuser_id && (!allow_input || allow_input == "false")) {
    return <div>Warten</div>;
  }
  return (
    <div id="Start">
      <div onClick={() => setPrefetch(true)}>Vorladen</div>
      {prefetch && <Prefetch data={options.prefetch} />}
      <img src={Logo} alt="Logo" className="Logo" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitForm(values)}
      >
        {({
          values,
          arrayHelpers,
          setFieldValue,
          errors,
          touched,
          isValidating,
          validateForm,
        }) => (
          <Form>
            {page === 1 && (
              <div>
                {allow_input == "true" ? (
                  ""
                ) : (
                  <>
                    <Field
                      name="testuser_id"
                      placeholder="Teilnehmer Nr."
                      type="text"
                      validate={validateTestId}
                      style={{
                        border: errors.testuser_id
                          ? "2px solid red"
                          : " 1px solid #ccc",
                      }}
                    />

                    <Field
                      name="test"
                      as="select"
                      validate={validateOption}
                      style={{
                        border: errors.test
                          ? "2px solid red"
                          : " 1px solid #ccc",
                      }}
                    >
                      <option className="placeholder">Test auswählen</option>
                      {options.tests &&
                        options.tests.map((test, idx) => (
                          <option key={`test-${idx}`} value={test.id}>
                            {test.name}
                          </option>
                        ))}
                    </Field>
                    <Field
                      name="name"
                      placeholder="Name"
                      validate={validateUsername}
                      style={{
                        border: errors.name
                          ? "2px solid red"
                          : " 1px solid #ccc",
                      }}
                    />
                  </>
                )}

                <Field
                  name="age"
                  as="select"
                  validate={validateOption}
                  style={{
                    border: errors.age ? "2px solid red" : " 1px solid #ccc",
                  }}
                >
                  <option className="placeholder" value="">
                    Altersgruppe auswählen
                  </option>
                  {options.alter &&
                    options.alter.map((age, idx) => (
                      <option key={`age-${idx}`} value={age.slug}>
                        {age.name}
                      </option>
                    ))}
                </Field>

                <Field
                  name="gender"
                  as="select"
                  validate={validateOption}
                  style={{
                    border: errors.gender ? "2px solid red" : " 1px solid #ccc",
                  }}
                >
                  <option className="placeholder" value="">
                    Geschlecht auswählen
                  </option>
                  {options.geschlechter &&
                    options.geschlechter.map((gender, idx) => (
                      <option key={`gender-${idx}`} value={gender.slug}>
                        {gender.name}
                      </option>
                    ))}
                </Field>
                <button onClick={() => setPage(2)}>Weiter</button>
                <div
                  className="slideshow"
                  style={{
                    position: "absolute",
                    bottom: 32,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Field type="checkbox" id="debug" name="debug"></Field>{" "}
                  <label htmlFor="debug">Debug Mode aktivieren</label>
                </div>
              </div>
            )}
            {page === 2 && (
              <div>
                <div
                  className="profile-upload"
                  style={{
                    backgroundImage: `url(${
                      values.bild ? values.bild : PlaceHolderImg
                    })`,
                  }}
                >
                  <input
                    type="file"
                    name="userImage"
                    onChange={(e) => uploadFile(e, setFieldValue)}
                  />
                </div>

                <FieldArray
                  name="tags"
                  render={(arrayHelpers) => (
                    <div className="tag-wrapper">
                      <div className="tag-list">
                        <div>
                          {values.tags &&
                            values.tags.map((tag, index) => (
                              <div
                                className="tag"
                                key={index}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <div name={`tags[${index}].name`}>
                                  {tag.name}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="tag-options">
                        {options.tags &&
                          options.tags.map((tag, idx) => {
                            let testID = options.tests.findIndex(
                              (ele) => ele.id == values.test
                            );
                            if (
                              tag.medium.includes(options.tests[testID].medium) //
                            ) {
                              return (
                                <div
                                  onClick={() => arrayHelpers.push(tag)}
                                  key={`tag-${idx}`}
                                  className="tag-placeholder"
                                >
                                  {tag.name}
                                </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  )}
                />
                <button className="form-back-button" onClick={() => setPage(1)}>
                  Zurück
                </button>
                <button type="submit">Test starten</button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

import "swiper/swiper-bundle.min.css";
// Import Swiper styles
import "swiper/swiper.scss";
import "./reals.scss";

import React, { useEffect, useRef, useState } from "react";
import { disableScroll, enableScroll } from "../../../util/functions";
import {
  toFacebook,
  toIgStory,
  toInstagram,
  toTiktok,
} from "../../../util/testTransition";
import { useHistory, useParams } from "react-router-dom";

import Footer from "../../tiktok/footer";
import LiveIMG from "../assets/ins-camera-white.svg";
import MusicAnimation from "../../tiktok/musikanimation";
import MusikTicker from "../../tiktok/musikticker";
import SearchIMG from "../assets/search.svg";
import Sidebar from "./Sidebar";
import Slider from "./Slider";
import Swiper from "swiper";
import Video from "./Video";
import { Waypoint } from "react-waypoint";
import cookie from "react-cookies";
import { useData } from "../../../util/dataProvider";

export default function Reals({ data, time }) {
  const { testId, testMode, debug } = useParams();
  const history = useHistory();
  const [callbackUrl, setCallbackUrl] = useState(cookie.load("callbackUrl"));
  const [tic, setTic] = useState(cookie.load("testuser_id"));
  const [sw, setSW] = useState(null);
  const [end, setEnd] = useState(false);

  const {
    trackingArray,
    settrackingArray,
    reset,
    setUserId,
    tiktokId,
    setTiktokId,
  } = useData();
  const endSwitch = useRef();

  var second = 0;

  function incrementSeconds() {
    second += 1;
  }

  setInterval(incrementSeconds, 1000);

  useEffect(() => {
    var swiper = new Swiper(".swiper-container", {
      direction: "vertical",
      autoHeight: true,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
      },
    });
    setSW(swiper);
    swiper.on("slideNextTransitionEnd", function () {
      let array = trackingArray;
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        item_id: data.posts[swiper.activeIndex - 1].id,
        action: "post_end_focus",
      });

      const videos = document.getElementsByTagName("video");

      let videoLength = videos[swiper.activeIndex - 1].duration;

      if (second < videoLength) {
        array.push({
          second: now,
          item_id: data.posts[swiper.activeIndex - 1].id,
          action: "track_canceled",
        });
      }

      if (data.posts[swiper.activeIndex]) {
        videos[swiper.activeIndex - 1].pause();
        videos[swiper.activeIndex].play();
      } else {
        videos[swiper.activeIndex - 1].pause();
      }

      if (data.posts[swiper.activeIndex]) {
        second = 0;
        array.push({
          second: now + 1,
          item_id: data.posts[swiper.activeIndex].id,
          action: "post_start_focus",
        });
      } else {
        array.push({
          second: now + 1,
          action: "scroll_bottom",
        });
      }
      settrackingArray([...array]);
    });

    swiper.on("slidePrevTransitionEnd", function () {
      let array = trackingArray;
      let now = Math.floor(Date.now());

      const videos = document.getElementsByTagName("video");
      if (data.posts[swiper.activeIndex + 1]) {
        videos[swiper.activeIndex + 1].pause();
        videos[swiper.activeIndex].play();
      } else {
        videos[swiper.activeIndex].play();
      }

      let videoLength = videos[swiper.activeIndex + 1].duration;
      if (videoLength > second) {
        array.push({
          second: now,
          item_id: data.posts[swiper.activeIndex + 1].id,
          action: "track_canceled",
        });
      }

      if (data.posts[swiper.activeIndex + 1]) {
        second = 0;
        array.push({
          second: now,
          item_id: data.posts[swiper.activeIndex + 1].id,
          action: "post_end_focus",
        });
      } else {
      }
      array.push({
        second: now + 1,
        item_id: data.posts[swiper.activeIndex].id,
        action: "post_start_focus",
      });

      settrackingArray([...array]);
    });
    swiper.init();
  }, []);

  useEffect(() => {
    setUserId(testId);
    let array = trackingArray;
    array.push({
      second: Math.floor(Date.now()),
      action: "start",
    });
    array.push({
      second: Math.floor(Date.now()),
      item_id: data.posts[0].id,
      action: "post_start_focus",
    });

    settrackingArray([...array]);
  }, []);

  function endTrack() {
    enableScroll();
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      action: "scroll_bottom",
    });
    settrackingArray([...array]);
  }

  function endTrack() {
    enableScroll();
    setEnd(true);
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      action: "scroll_bottom",
    });
    settrackingArray([...array]);
  }

  return (
    <div id="reels">
      {testMode == "slideshow" && !end && (
        <div className="slideshow-cover"></div>
      )}
      <div className="reel-header">
        <b>Reels</b>
      </div>
      <img src={LiveIMG} alt="Search" className="live" />

      <div></div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {data.posts &&
            data.posts.map((post, idx) => {
              if (post.inhalt?.acf_fc_layout === "slider") {
                return (
                  <Slider
                    post={post}
                    idx={idx}
                    slideshow={testMode}
                    swiper={sw}
                    time={time}
                  ></Slider>
                );
              }
              return (
                <Video
                  post={post}
                  idx={idx}
                  slideshow={testMode}
                  swiper={sw}
                  time={time}
                ></Video>
              );
            })}
          <div className="swiper-slide">
            {callbackUrl ? (
              <button
                style={{ marginTop: "2rem" }}
                className="test-end"
                onClick={function () {
                  const callbackUrlReplaced = callbackUrl
                    .replace("https://", "")
                    ?.replace("http//", "");
                  reset();
                  window.location.href =
                    "https://" + callbackUrlReplaced + "?m=" + tic;
                }}
              >
                Test beenden
              </button>
            ) : (
              <Waypoint
                scrollableAncestor={window}
                topOffset={"50px"}
                bottomOffset={"50px"}
                onEnter={() => endTrack()}
              >
                <div ref={endSwitch} id="tiktok-end">
                  {testMode === "normal" ? (
                    <button
                      className="test-end"
                      onClick={function () {
                        reset();
                        history.push(
                          `/test/${testId}/slideshow/${
                            debug == "debug" ? "debug" : "normal"
                          }`
                        );
                      }}
                    >
                      Diashow starten
                    </button>
                  ) : (
                    <button
                      className="test-end"
                      onClick={function () {
                        reset();
                        history.push(
                          `/test/${testId}/normal/${
                            debug == "debug" ? "debug" : "normal"
                          }`
                        );
                      }}
                    >
                      Scroll modus starten
                    </button>
                  )}
                  {testMode !== "normal" && (
                    <button
                      className="test-end"
                      onClick={function () {
                        reset();
                        history.push(`/`);
                      }}
                    >
                      Test beenden
                    </button>
                  )}
                </div>
              </Waypoint>
            )}
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";

import Slider from "react-slick";

import { useData } from "../../../util/dataProvider";
import MusicAnimation from "../../tiktok/musikanimation";
import MusikTicker from "../../tiktok/musikticker";
import Sidebar from "./Sidebar";

export default function Video({ idx, post, swiper, slideshow, time }) {
  const [slideCount, setSlideCount] = useState(0);
  const { trackingArray, settrackingArray } = useData();

  const settings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    dots: true,
    beforeChange: (oldIndex, newIndex) => track(oldIndex, newIndex, post.id),
  };

  const next = () => {
    if ((time / 100) % 5 !== 0) {
      setTimeout(() => {
        swiper.slideNext();
      }, 5000 - ((time / 100) % 5) * 1000);
    } else {
      swiper.slideNext();
    }
  };

  const sliderRef = useRef();

  useEffect(() => {
    if (slideshow === "slideshow") {
      setInterval(() => {
        if (sliderRef.current) {
          sliderRef.current.slickNext();
          setSlideCount((old) => old + 1);
        }
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (slideCount === post?.inhalt?.bilder?.length) {
      swiper.slideNext();
    }
  }, [slideCount]);

  function track(oldIndex, newIndex, id) {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      item_id: id,
      action: `carousel_leave_${oldIndex + 1}`,
    });
    array.push({
      second: now,
      item_id: id,
      action: `carousel_enter_${newIndex + 1}`,
    });
    settrackingArray([...array]);
  }

  return (
    <div className="swiper-slide insta-reel-slider" key={"tiktok-video" + idx}>
      <Slider ref={sliderRef} {...settings}>
        {post?.inhalt?.bilder &&
          post?.inhalt?.bilder.map((item, idx) => {
            return (
              <div className="tiktok-slide" key={`slicker-${idx}`}>
                <img className="slicker-bild" src={item} />
              </div>
            );
          })}
      </Slider>
      <video src="" style={{ display: "none" }} />
      <div className="reels-leftbar">
        <div className="user-name">
          <div
            className="reels-author"
            style={{
              backgroundImage: `url(${post.autor.profilbild})`,
            }}
          ></div>
          <div>{post.autor.name}</div>

          <div className="reels-folgen">Folgen</div>
        </div>
        {post.inhalt.text && (
          <div
            className="tiktok-caption"
            dangerouslySetInnerHTML={{
              __html: post.inhalt.text,
            }}
          ></div>
        )}
        {post.sponsored && <div className="sponsor">Gesponsert</div>}
        <MusikTicker title={post.inhalt.sound_text} />
        {post.inhalt.button_text && (
          <div
            className="werb-button"
            style={{
              backgroundColor: post.inhalt.button_color,
            }}
          >
            {post.inhalt.button_text}{" "}
          </div>
        )}
      </div>
      <Sidebar data={post} />
      <MusicAnimation />
    </div>
  );
}

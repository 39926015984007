import React from "react";

export default function Footer() {
  return (
    <div className="tik-tok-footer">
      <div className="home">
        <div className="home-icon"></div>
        Home
      </div>
      <div className="friends">
        <div className="friends-icon"></div>
        Freund*innen
      </div>
      <div className="add-box" style={{ position: "relative" }}></div>
      <div className="mail">
        <div className="mail-icon"></div>
        Posteingang
      </div>
      <div className="user">
        <div className="user-icon"></div>
        Profil
      </div>
    </div>
  );
}
